import React from 'react';

import HelmetLayout from '../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../components/layouts/main_layout/main_layout';
import ContactContent from '../components/contact/contact_content/contact_content';

const Contact = () => (
	<HelmetLayout>
		<MainLayout showFooter={false}>
			<ContactContent />
		</MainLayout>
	</HelmetLayout>
);

export default Contact;
