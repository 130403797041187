import React from 'react';

import BackgroundImage from '../../../assets/images/pages/contact/background.png';
import BackgroundImageLowDef from '../../../assets/images/pages/contact/background_low_def.png';

import ContactCard from './contact_card/contact_card';
import AllRightsReserved from '../../small_views/all_rights_reserved/all_rights_reserved';
import OptimisedImage from '../../small_views/optimised_image/optimised_image';

import classes from './contact_content.module.css';

const ContactContent = () => (
	<div className={classes.container}>
		<div className={classes.backgroundContainer}>
			<OptimisedImage
				alt="Karnott - Contactez-nous !"
				className={classes.background}
				normal={BackgroundImage}
				lowDef={BackgroundImageLowDef}
			/>
		</div>
		<div className={classes.content}>
			<h1 className={classes.title}>
				{'Dîtes-nous tout :)'}
			</h1>
			<ContactCard />
			<AllRightsReserved className={classes.allRightsReserved} />
		</div>
	</div>
);

export default ContactContent;
