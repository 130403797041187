import React, { useCallback, useState } from 'react';

import cn from 'classnames';

import Card from '../../../design_system/card/card';
import TextField from '../../../design_system/text_field/text_field';
import TextFieldLabel from '../../../design_system/text_field/text_field_label/text_field_label';
import Checkbox from '../../../design_system/checkbox/checkbox';
import Button from '../../../design_system/button/button';

import formData from '../form_data';

import classes from './contact_card.module.css';

const MAX_MESSAGE_LENGTH = 450;

const getDefaultFieldsState = () => Object.keys(formData).reduce((state, id) => {
	const newState = state;
	newState[id] = {
		value: '',
		error: null
	}
	return newState;
}, {});

const ContactCard = ({ className }) => {
	const [fields, setFields] = useState(getDefaultFieldsState());
	const [, updateState] = useState();
	const [message, setMessage] = useState('');
	const [acceptanceChecked, setAcceptanceChecked] = useState(false);
	const handleAcceptanceChange = useCallback((event) => {
		setAcceptanceChecked(event.target.checked);
	});
	const handleChange = inputId => (event) => {
		if (!inputId || event.target.value === undefined) {
			return;
		}
		const newFields = fields;
		newFields[inputId].value = event.target.value;
		setFields(newFields);
		updateState({});
	};
	const handleMessageChange = useCallback((event) => {
		if (event.target.value === undefined) {
			return;
		}
		const { value } = event.target;
		setMessage(value);
	});
	return (
		<Card
			className={cn(classes.container, className)}
			color="emerald"
		>
			<div className={classes.textFieldsContainer}>
				{Object.entries(formData).map(([id, field]) => (
					<Field
						key={`contact_field_${id}`}
						onChange={handleChange(id)}
						{...field}
						{...fields[id]}
					/>
				))}
			</div>
			<div className={classes.areaSubmitContainer}>
				<TextFieldLabel className={classes.areaLabel}>
					{'Votre demande'}
				</TextFieldLabel>
				<TextField
					className={classes.area}
					inputClassName={classes.areaInput}
					multiline
					rows={7}
					variant="outlined"
					onChange={handleMessageChange}
					value={message}
				>
					<div className={classes.charactersCount}>
						{`${MAX_MESSAGE_LENGTH - message.length} caractères restant(s).`}
					</div>
				</TextField>
				<div className={classes.checkAcceptanceRow}>
					<Checkbox
						className={classes.checkbox}
						checked={acceptanceChecked}
						onChange={handleAcceptanceChange}
						variant="outlined"
					/>
					<div>
						{"J'accepte que Karnott traite mes données conformément à la politique de confidentialité."}
					</div>
				</div>
				<div className={classes.actions}>
					<Button
						className={classes.button}
						variant="outlined"
					>
						{'Envoyer ma demande >'}
					</Button>
				</div>
			</div>
		</Card>
	);
};

const Field = ({
	label, type, onChange, value
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const handleFocus = useCallback(() => {
		setIsFocused(true);
	});
	const handleBlur = useCallback(() => {
		setIsFocused(false);
	});
	return (
		<div className={classes.field}>
			<TextField
				className={classes.textField}
				onFocus={handleFocus}
				onBlur={handleBlur}
				beforeChildren={(
					<TextFieldLabel
						className={classes.label}
						style={{
							color: isFocused ? 'rgba(255, 255, 255, .75)' : '#fff'
						}}
					>
						{label}
					</TextFieldLabel>
				)}
				{...{ type, value, onChange }}
			/>
		</div>
	);
};

export default ContactCard;
