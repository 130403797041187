const formData = Object.freeze({
	lastName: {
		label: 'Nom',
		type: 'text'
	},
	firstName: {
		label: 'Prénom',
		type: 'text'
	},
	mail: {
		label: 'Mail',
		type: 'email'
	},
	phoneNumber: {
		label: 'Numéro de tél.',
		type: 'phoneNumber'
	},
	postalCode: {
		label: 'Code postal',
		type: 'text'
	},
	company: {
		label: 'Société',
		type: 'text'
	},
	acitivity: {
		label: 'Activité',
		type: 'text'
	}
});

export default formData;
